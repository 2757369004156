import { Observable } from './Observable.js';
import Cookies from 'js-cookie';

let BeirholmDigitalWarehouse = BeirholmDigitalWarehouse || {};
BeirholmDigitalWarehouse = {
    AssetsBulkDownload: {
        StateObserver: new Observable(),
        /**
         * Subscribers observer to listen on assets bulk download state changes
         * @param {any} observer
         */
        SubscribeToChanges: function (observer) {
            this.StateObserver.subscribe(observer);
        },
        /**
         * Returns if asset is part of bulk download assets list
         * @param {any} asset path of the asset
         */
        Exists: function (asset) {
            const assetsForDownloading = this.Storage.GetAll();
            return assetsForDownloading.indexOf(asset) >= 0;
        },
        /**
         * Adds asset to bulk download list
         * @param {any} asset path of the asset
         */
        Add: function (asset) {
            // check in order to avoid adding dublicates to assets download array
            if (this.Exists(asset)) {
                return;
            }

            const assets = this.Storage.GetAll();
            assets.push(asset);

            this.Storage.Set(assets);
            this.StateObserver.notify({
                action: 'changed',
                data: assets
            });
        },
        /**
        * Removes asset from bulk download list
        * @param {any} asset path of the asset
        */
        Remove: function (asset) {
            if (!this.Exists(asset)) {
                // is not present in assets to download array
                return;
            }

            const assets = this.Storage.GetAll();
            assets.splice(assets.indexOf(asset), 1);

            this.Storage.Set(assets);
            this.StateObserver.notify({
                action: 'changed',
                data: assets
            });
        },
        /**
         * Removes all assets from download list 
         **/
        Clear: function () {
            this.Storage.Clear();
            this.StateObserver.notify({
                action: 'cleared'
            });
        },
        Storage: {
            /**
             * Settings of persistance cookie
             */
            CookieSettings: {
                CookieName: "DigitalWarehouseAssetsDownload",
                ExpirationDays: 30
            },
            /**
             * Returns all assets in the list
             **/
            GetAll: function () {
                const resultCookie = Cookies.get(this.CookieSettings.CookieName);
                if (!resultCookie) {
                    return [];
                }

                const assetsDownloadState = JSON.parse(resultCookie);
                return assetsDownloadState?.assets || [];
            },
            /**
             * Updates the state of assets in the download list
             * @param {any} assets
             */
            Set: function (assets) {
                const state = {
                    assets: assets
                    // available for extendibility if needed later on
                };
                Cookies.set(this.CookieSettings.CookieName, JSON.stringify(state), { expires: this.CookieSettings.ExpirationDays });
            },
            /**
             * Removes all assets from the list, by deleting the cookie
             **/
            Clear: function () {
                const assets = this.GetAll();
                if (assets.length < 1) {
                    return;
                }

                Cookies.remove(this.CookieSettings.CookieName);
            }
        }
    }
};

export { BeirholmDigitalWarehouse };