import { Beirholm } from './Beirholm.js';
import { BeirholmDigitalWarehouse } from './DigitalWarehouse.js';


// When the user scrolls down 50px from the top of the document, change bg color of Top
//document.addEventListener("DOMContentLoaded", function () {
//    console.log('Your document is ready!');
//});

//let didScroll = false;

//window.addEventListener('scroll', function () {
//    didScroll = true;
//    console.log('scroll');
//});

//setInterval(function () {
//    if (didScroll) {
//        didScroll = false;

//        const nav = document.getElementById('Top');
//        if (window.scrollY > 50) {
//            nav.classList.add('scrolled');
//        } else {
//            nav.classList.remove('scrolled');
//        }
//    }
//}, 100);

window.Beirholm = Beirholm;
window.BeirholmDigitalWarehouse = BeirholmDigitalWarehouse;