const Beirholm = function () {

    var modalContainer,
        backdrop,
        modal,
        modalHeader,
        modalBody,
        btnGroup,
        btnCancel,
        glsParcelRadio = document.querySelector('[data-shipping-provider="beirholm-custommodules-glscustom"]'),
        ecomAddress = document.getElementById('EcomOrderCustomerAddress'),
        ecomZip = document.getElementById('EcomOrderCustomerZip');
    
    return {

        AddToCart: function () {
            var args = Array.prototype.slice.call(arguments);

            this.ValidateQuantity.apply(this, args);
        },

        ValidateQuantity: function (e, cartItem) {
            //console.log(cartItem);

            if (cartItem.quantity == 0 || cartItem.quantity == "" || cartItem.quantity == null || isNaN(cartItem.quantity)) {
                this.CreateModal(e.currentTarget.getAttribute('data-error-empty'), e.currentTarget.getAttribute('data-error-header'), e.currentTarget.getAttribute('data-error-btn'))
                return false;
            }

            if (cartItem.min) {
                if (cartItem.quantity < cartItem.min) {
                    this.CreateModal(e.currentTarget.getAttribute('data-error-min'), e.currentTarget.getAttribute('data-error-header'), e.currentTarget.getAttribute('data-error-btn'))
                    return false;
                }
            }

            if (cartItem.max) {
                if (cartItem.quantity > cartItem.max) {
                    this.CreateModal(e.currentTarget.getAttribute('data-error-max'), e.currentTarget.getAttribute('data-error-header'), e.currentTarget.getAttribute('data-error-btn'))
                    return false;
                }
            }

            if (cartItem.stock) {
                if (cartItem.quantity > cartItem.stock) {
                    this.CreateModal(e.currentTarget.getAttribute('data-error-stock'), e.currentTarget.getAttribute('data-error-header'), e.currentTarget.getAttribute('data-error-btn'))
                    return false;
                }
            }

            if ((cartItem.quantity % parseInt(cartItem.step)) != 0) {
                this.CreateModal(e.currentTarget.getAttribute('data-error-step'), e.currentTarget.getAttribute('data-error-header'), e.currentTarget.getAttribute('data-error-btn'))
                return false;
            }

            this.AddToCartByObject(e, cartItem);
        },

        ValidateOrderlineQuantity: function (cartItem) {
            //console.log(cartItem);

            if (parseInt(cartItem.quantity) == 0 || cartItem.quantity == "" || cartItem.quantity == null || isNaN(cartItem.quantity)) {
                this.CreateModal(cartItem.errorEmpty, cartItem.errorHeader, cartItem.errorBtn)
                return false;
            }

            if (cartItem.min) {
                if (parseInt(cartItem.quantity) < parseInt(cartItem.min)) {
                    this.CreateModal(cartItem.errorMin, cartItem.errorHeader, cartItem.errorBtn)
                    return false;
                }
            }

            if (cartItem.max) {
                if (parseInt(cartItem.quantity) > parseInt(cartItem.max)) {
                    this.CreateModal(cartItem.errorMax, cartItem.errorHeader, cartItem.errorBtn)
                    return false;
                }
            }

            if (cartItem.stock) {
                if (parseInt(cartItem.quantity) > parseInt(cartItem.stock)) {
                    this.CreateModal(cartItem.errorStock, cartItem.errorHeader, cartItem.errorBtn)
                    return false;
                }
            }

            if ((parseInt(cartItem.quantity) % parseInt(cartItem.step)) != 0) {
                this.CreateModal(cartItem.errorStep, cartItem.errorHeader, cartItem.errorBtn)
                return false;
            }

            return true;

        },

        CreateModal: function (errorMessage, errorHeader, errorBtn, callback) {
            modalContainer = document.createElement("div");
            modalContainer.classList.add('modal-container');
            modalContainer.style.display = 'flex';
            document.body.appendChild(modalContainer);

            backdrop = document.createElement("div");
            backdrop.classList.add('modal-overlay');
            backdrop.addEventListener('click', this.CloseModal);
            modalContainer.appendChild(backdrop);

            modal = document.createElement("div");
            modal.classList.add('modal', 'modal--xs');
            modalContainer.appendChild(modal);

            modalHeader = document.createElement("div");
            modalHeader.classList.add('modal__header');
            modalHeader.innerHTML = '<h3 class="u-line-height--normal u-no-margin">' + errorHeader + '</h3>';
            modal.appendChild(modalHeader);

            modalBody = document.createElement("div");
            modalBody.classList.add('modal__body');
            modalBody.innerHTML = '<p>' + errorMessage + '</p>';

            btnGroup = document.createElement("div");
            btnGroup.classList.add('buttons-collection', 'form__fields-collection');
            modalBody.appendChild(btnGroup);

            btnCancel = document.createElement("button");
            btnCancel.setAttribute('type', 'button');
            btnCancel.classList.add('btn', 'btn--primary', 'dw-mod');
            btnCancel.textContent = errorBtn;
            btnCancel.addEventListener('click', callback = callback ? callback : this.CloseModal), false;
            btnGroup.appendChild(btnCancel);

            modal.appendChild(modalBody);
        },

        CloseModal: function () {
            if (modalContainer) {
                modalContainer.remove();
            }
            if (modal) {
                modal.remove();
            }

            if (backdrop) {
                backdrop.remove();
            }
        },

        AddToCartByObject: function (e, cartItem) {

            var miniCart = document.getElementsByClassName('js-mini-cart')[0];
            var cartId = miniCart ? miniCart.getAttribute('data-cart-id') : window.cartId;
            e.preventDefault();
            if (cartItem.quantity <= 0) {
                return;
            }

            var clickedButton = e.currentTarget;
            var clickedButtonText = clickedButton.innerHTML;
            var clickedButtonWidth = clickedButton.offsetWidth;
            var clickedButtonStyleWidth = clickedButton.style.width;

            clickedButton.classList.add("disabled");
            clickedButton.disabled = true;
            clickedButton.innerHTML = "<i class=\"fas fa-circle-notch fa-spin\"></i>";
            clickedButton.style.width = clickedButtonWidth + "px";

            setTimeout(function () {
                clickedButton.classList.remove("disabled");
                clickedButton.disabled = false;
                clickedButton.innerHTML = clickedButtonText;
                clickedButton.style.width = clickedButtonStyleWidth;
            }, 1000);

            var url = "/Default.aspx?ID=" + cartId;
            url += "&Quantity=" + cartItem.quantity;
            url += "&redirect=false";
            url += "&ProductID=" + cartItem.id;
            if (cartItem.unitId) {
                url += "&UnitID=" + cartItem.unitId;
            }
            if (cartItem.variantId) {
                url += "&VariantID=" + cartItem.variantId;
            }

            this.UpdateCart('miniCart', url, cartItem.buyForPoints ? "cartcmd=addWithPoints" : "cartcmd=add", false, cartItem, clickedButton);
        },

        UpdateCart: function (containerId, url, command, preloader, cartItem, clickedButton) {
            const self = Cart;

            if (preloader) {
                var overlayElement = document.createElement('div');
                overlayElement.className = "preloader-overlay";
                overlayElement.setAttribute('id', "CartOverlay");
                var overlayElementIcon = document.createElement('div');
                overlayElementIcon.className = "preloader-overlay__icon dw-mod";
                overlayElementIcon.style.top = window.pageYOffset + "px";
                overlayElement.appendChild(overlayElementIcon);

                document.getElementById('content').parentNode.insertBefore(overlayElement, document.getElementById('content'));
            }

            var miniCartButtons = document.getElementsByClassName("js-mini-cart-button");
            for (var i = 0; i < miniCartButtons.length; i++) {
                var cartButton = document.getElementsByClassName("js-mini-cart-button")[i];
                cartButton.classList.add("mini-cart-update");
            }

            var queryParams = new QueryArray(url);
            queryParams.combineWithParams(command);
            queryParams.setValue("feedtype", "Counter");

            Request.Fetch().get(queryParams.getFullUrl(), updateSuccess, updateFailed);

            function updateSuccess(data) {

                //Does cart maximum validate
                if (!data[0].maximumValidation) {
                    //console.log(cartItem);
                    //console.log(clickedButton);
                    Beirholm.CreateModal(clickedButton.getAttribute('data-error-max'), clickedButton.getAttribute('data-error-header'), clickedButton.getAttribute('data-error-btn'), function () { location.reload(); });
                    return;
                }

                //Does cart maximum validate
                if (!data[0].stockValidation) {
                    //console.log(cartItem);
                    //console.log(clickedButton);
                    Beirholm.CreateModal(clickedButton.getAttribute('data-error-stock'), clickedButton.getAttribute('data-error-header'), clickedButton.getAttribute('data-error-btn'), function () { location.reload(); });
                    return;
                }

                if (preloader) {
                    var overlayNode = document.getElementById('CartOverlay');
                    overlayNode.parentNode.removeChild(overlayNode);
                }

                if (document.getElementById(containerId) && containerId != "miniCart") {
                    HandlebarsBolt.UpdateContent(containerId, url);
                }

                var miniCartCounters = document.getElementsByClassName("js-mini-cart-counter");

                for (var i = 0; i < miniCartCounters.length; i++) {
                    var cartCounter = miniCartCounters[i];

                    if (cartCounter) {
                        cartCounter.innerHTML = "";
                        var miniCartButtons = document.getElementsByClassName("js-mini-cart-button");
                        for (var i = 0; i < miniCartButtons.length; i++) {
                            var cartButton = document.getElementsByClassName("js-mini-cart-button")[i];
                            cartButton.classList.remove("mini-cart-update");
                        }

                        HandlebarsBolt.CreateItemsFromJson(data, cartCounter.getAttribute("id"));
                    }
                }

                if (self.miniCartId) {
                    let isMiniCartVisible = self.IsMiniCartVisible(self.miniCartId);

                    if (data[0].numberofproducts == 0) {
                        if (isMiniCartVisible) {
                            if (self.showType == "dropdown" && document.getElementById(containerId)) {
                                document.getElementById(containerId).style.display = "none";
                            } else {
                                let trigger = document.getElementById("miniCartTrigger");
                                if (trigger) {
                                    trigger.checked = false
                                    trigger.classList.remove('js-active');
                                }
                            }
                        }
                    } else if (isMiniCartVisible) {
                        Cart.UpdateMiniCartContainer(self.miniCartId, url);
                    }
                }

                if (cartItem != null) {
                    //add
                    var event = new CustomEvent('addToCart', { 'detail': cartItem });
                    document.dispatchEvent(event);
                }

                var event = new CustomEvent('cartUpdated', { 'detail': { "command": command, "containerId": containerId, "url": url, "preloader": preloader, "data": data } });
                document.dispatchEvent(event);
            }

            function updateFailed(data) {
                location.reload();

                var event = new CustomEvent('cartUpdated', { 'detail': { "command": command, "containerId": containerId, "url": url, "preloader": preloader, "data": data } });
                document.dispatchEvent(event);
            }
        },

        ChangeQuantity: function (cartOrderlinesFeedPageId, orderLineId, quantity, cartItem) {
            quantity = '&QuantityOrderLine' + orderLineId + '=' + quantity;
            let comment = document.getElementById('EcomOrderCustomerComment');
            let accept = document.getElementById('EcomOrderCustomerAccepted');
            let customerComment = comment ? '&EcomOrderCustomerComment=' + comment.value : "";
            let customerAccepted = accept ? ('&EcomOrderCustomerAccepted=' + (accept.checked ? true : '')) : "";

            if (this.ValidateOrderlineQuantity(cartItem)) {
                clearTimeout(updateDelay);
                updateDelay = setTimeout(function () {
                    Cart.UpdateCart(null,
                        '/Default.aspx?ID=' + cartOrderlinesFeedPageId,
                        '&CartCmd=UpdateOrderlines' + quantity + customerComment + customerAccepted + '&redirect=false',
                        true);
                }, 800);
            }

            return;
        },

        ValidateZip: function (str) {
            var validZip = /^[0-9]{4}?$/;
            if (validZip.test(str)) {
                return true;
            }
            else {
                return false;
            }
        },

        ValidateField: function (str) {
            if (str.length > 0) {
                return true;
            }
            else {
                return false;
            }
        },

        GlsWarningUpdate: function (validated) {
            var warning = document.getElementById("EcomOrderCustomerZip_Warning");

            if (validated == true) {
                warning.classList.add('u-hidden');    
            } else if (validated == false) {
                warning.classList.remove('u-hidden');
            }
        },

        GlsWarning: function (warning) {
            var warningText = document.createElement("small");
            warningText.id = "EcomOrderCustomerZip_Warning";
            warningText.classList.add('u-block', 'u-color-danger', 'u-font-size--xs');
            warningText.innerText = warning;
            glsParcelRadio.nextElementSibling.after(warningText);
        },

        GlsParcelOption: function () {
            var validated;

            if (this.ValidateZip(ecomZip.value) && this.ValidateField(ecomAddress.value)) {
                validated = true;
            } else {
                validated = false;
            }
            //console.log('GlsParcelOption:' + validated);

            if (validated == false) {
                glsParcelRadio.disabled = true;
                
                this.GlsWarningUpdate(validated);
                return false;
            }

            if (validated == true) {
                glsParcelRadio.disabled = false;
                this.GlsWarningUpdate(validated);
                return true;
            }
        },

        EnableCheckoutButton: function () {
            var parcelShopError = document.getElementById("parcelshop-error-message");
            var stepButtonId = document.getElementById("CartV2.GotoStep3") ? "CartV2.GotoStep3" : "CartV2.GotoStep1";
            var stepButton = document.getElementById(stepButtonId);

            if (document.getElementById("EcomOrderCustomerAccepted").checked && parcelShopError == null) {
                stepButton.disabled = false;
                stepButton.classList.remove('disabled');
            } else {
                stepButton.disabled = true;
                stepButton.classList.add('disabled');
            }
        }
    }
}();

export { Beirholm };